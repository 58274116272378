import { ArrayPath, FieldArray, FieldValues, useFieldArray } from 'react-hook-form'
import { ComponentType, memo, useCallback, useContext, useEffect, useState } from 'react'
import { PortfolioSplitsColumns } from './portfolioSplitsColumns'
import { CategoryTable, CategoryTableProps, DynamicTable } from './CategoryTable'
import { FieldArrayPath } from 'react-hook-form/dist/types/path'
import { NcpContext, NcpDispatchContext } from '../../providers/NewCustomPortfolioStateProvider'
import { assertNotNil } from '../../utils/assertNotNil'
import { MonthType, PortfolioSplitTypes } from '../../backend/new-custom-portfolio-items'
import { CategoryTableOption, DynamicContentEditorProps, DynamicFields } from './types'
import { sanitizeId } from '../../utils/ids'

export type UserSelectsColumns = 'user'
export type AutoSelectAllColumns = 'auto'

type ColumnsRenderStrategy = AutoSelectAllColumns | UserSelectsColumns

type DynamicCategoryTableProps<
  TFieldValues extends FieldValues,
  TRenderStrategy extends ColumnsRenderStrategy = AutoSelectAllColumns,
> = {
  columnsOptions: Omit<CategoryTableOption, 'checked'>[]
} & Omit<CategoryTableProps<TFieldValues, DynamicTable>, 'columns' | 'formContentEditor'> &
  (TRenderStrategy extends UserSelectsColumns ? { ContentEditor: ComponentType<DynamicContentEditorProps> } : {})

type DynamicFieldValues = FieldValues & { [key: string]: DynamicFields }

export const NonMemoizedDynamicCategoryTable = <
  TFieldValues extends DynamicFieldValues,
  TRenderStrategy extends ColumnsRenderStrategy,
>(
  props: DynamicCategoryTableProps<TFieldValues, TRenderStrategy>,
) => {
  const columnsRenderStrategyIsUser: boolean = 'ContentEditor' in props
  const columnsRenderStrategyIsAuto: boolean = !columnsRenderStrategyIsUser

  const {
    fields: formFields,
    append,
    remove,
  } = useFieldArray<TFieldValues>({
    name: props.dynamicFieldsName as FieldArrayPath<TFieldValues>,
  })
  const [columnLabels, setColumnLabels] = useState<PortfolioSplitsColumns>([])
  const [options, setOptions] = useState<CategoryTableOption[]>(
    props.columnsOptions.map((opt) => ({ ...opt, checked: false })),
  )
  const [resetContentEditor, setResetContentEditor] = useState<boolean>(false)

  const ncpDispatch = useContext(NcpDispatchContext)
  assertNotNil(ncpDispatch)
  const ncpState = useContext(NcpContext)
  assertNotNil(ncpState)

  const addColumn: (option: CategoryTableOption) => void = useCallback(
    (option) => {
      setColumnLabels([...columnLabels, option.label])
      setOptions((prevState) =>
        prevState.map((opt) => {
          if (opt.label === option.label) {
            opt.checked = true
          }
          return opt
        }),
      )
      append({ value: 0 } as FieldArray<TFieldValues, ArrayPath<TFieldValues>>, { shouldFocus: false })
      ncpDispatch({
        type: 'updateSingleKeyForCategoryWeighting',
        payload: { categoryName: props.categoryName, key: option.label, data: 0 },
      })
    },
    [columnLabels, append],
  )

  const removeColumn: (option: CategoryTableOption) => void = useCallback(
    (option) => {
      remove(columnLabels.indexOf(option.label))
      setColumnLabels(columnLabels.filter((label) => label !== option.label))
      setOptions((prevState) =>
        prevState.map((opt) => {
          if (opt.label === option.label) {
            opt.checked = false
          }
          return opt
        }),
      )
      ncpDispatch({
        type: 'removeSingleKeyFromDynamicCategoryWeighting',
        payload: { categoryName: props.categoryName, key: option.label },
      })
    },
    [columnLabels, remove],
  )

  useEffect(() => {
    // TODO make this effect less verbose :)
    const populateDynamicTableWithDataFromNcpStateOnFirstRender = () => {
      const storedCategoryData = ncpState.ncpData.categoryWeightings[props.categoryName]

      if (columnsRenderStrategyIsUser) {
        const newLabels: PortfolioSplitsColumns = []
        const newOptions: CategoryTableOption[] = options
        const valuesToAppend: number[] = []
        for (const label in storedCategoryData) {
          newLabels.push(label)
          const index = newOptions.findIndex((option) => option.label === label)
          newOptions[index] = { ...newOptions[index], checked: true }
          valuesToAppend.push(storedCategoryData[label as keyof (MonthType | PortfolioSplitTypes)])
        }
        setColumnLabels(newLabels)
        setOptions(newOptions)
        append(valuesToAppend.map((value) => ({ value })) as FieldArray<TFieldValues, ArrayPath<TFieldValues>>, {
          shouldFocus: false,
        })
      } else if (columnsRenderStrategyIsAuto) {
        setColumnLabels(props.columnsOptions.map((option) => option.label))
        setOptions((prevState) => prevState.map((opt) => ({ ...opt, checked: true })))
        append(
          props.columnsOptions.map(
            (option) =>
              ({
                value: storedCategoryData[option.label as keyof (MonthType | PortfolioSplitTypes)] ?? 0,
              }) as FieldArray<TFieldValues, ArrayPath<TFieldValues>>,
          ),
          { shouldFocus: false },
        )
        Object.getOwnPropertyNames(storedCategoryData).length === 0 &&
          props.columnsOptions.forEach((option) => {
            ncpDispatch({
              type: 'updateSingleKeyForCategoryWeighting',
              payload: {
                categoryName: props.categoryName,
                key: option.label,
                data: 0,
              },
            })
          })
      }
    }

    populateDynamicTableWithDataFromNcpStateOnFirstRender()

    return remove
  }, [])

  useEffect(() => {
    const emptyTableOnResetForUserRenderStrategy = () => {
      if (ncpState.reset && columnsRenderStrategyIsUser) {
        setColumnLabels([])
        setOptions((prevState) => prevState.map((opt) => ({ ...opt, checked: false })))
        setResetContentEditor(true)
        remove()
        ncpDispatch({ type: 'reset', payload: { reset: false } })
      }
    }

    emptyTableOnResetForUserRenderStrategy()
  }, [ncpState.reset])

  useEffect(() => {
    if (resetContentEditor) {
      setResetContentEditor(false)
    }
  }, [resetContentEditor])

  const ContentEditor = 'ContentEditor' in props ? props.ContentEditor : undefined

  return (
    <CategoryTable<TFieldValues, DynamicTable>
      {...props}
      columns={{
        labels: columnLabels,
        fields: formFields,
      }}
      formContentEditor={
        ContentEditor ? (
          <div id={sanitizeId(`${props.categoryLabel}-content-editor`)}>
            <ContentEditor
              options={options}
              onSelect={addColumn}
              onUnselect={removeColumn}
              maxSelections={10}
              shouldReset={resetContentEditor}
            />
          </div>
        ) : undefined
      }
    />
  )
}

/**
 * @description Dynamic Category Table component.
 * To be used exclusively for Portfolio Splits Table.
 *
 * Prerequisites:
 * - Expected to be used within <b>FormProvider</b> from <i>React Hook Form</i>.
 * If not, it will throw an error. This means, that the parent component is responsible for initializing the form to be used for displaying columns with respective values.
 * - <b>Always initialize the form with empty array of fields.</b> Based on the columns render strategy, either User populates the table, or <b>DynamicCategoryTable</b> does it automatically for you.
 * - Expected to be used underneath <b>NewCustomPortfolioStateProvider</b> from <i>providers</i> folder. It is necessary for the component to read and update ncpState data properly.
 *
 * <b>For docs and example of using static tables (meaning, you as a dev know number and names of columns to be present in the table upfront), see <i>CategoryTable</i> component.</b>
 *
 * Reusability outside of this context is not guaranteed, though probably possible with some changes.
 *
 * For description of <i>props</i> and <i>generic types</i>, read on.
 *
 * For usage example, see e.g. <i>ExposureRegionTable</i> component.
 *
 * @param TFieldValues
 * - Generic type representing the fields of the table.
 * This type extends <b>FieldValues</b> which is type for fields definition coming from <i>React Hook Form</i>.
 *
 * @param TRenderStrategy
 * - Generic type representing whether the dynamic columns are going to be added (removed) to table by <b>User</b> interaction
 * or <b>Auto</b>matically populating the table with all options provided from the beginning.
 *
 * @param columnsOptions
 * - All options to be considered as columns in the table.
 *   - If we set render strategy to <b>User</b>, user will be responsible for selecting which columns to display.
 *   - If we set render strategy to <b>Auto</b>, all provided columns will be displayed from the beginning.
 *
 * @param ContentEditor
 * - Required if strategy is set to <b>User</b>.
 * It will be used to let user interact with the contents of the table (columns).
 * - This component has to conform to <i>DynamicContentEditorProps</i> type.
 *
 * For docs of other unlisted props, see <i>CategoryTable</i> component's docs.
 */
export const DynamicCategoryTable = memo(NonMemoizedDynamicCategoryTable) as typeof NonMemoizedDynamicCategoryTable
