export function sanitizeId(target: string) {
  return target
    .replaceAll(' ', '-')
    .replaceAll('&', 'and')
    .replaceAll('null', '')
    .replaceAll('undefined', '')
    .replaceAll('---', '-')
    .replaceAll('--', '-')
    .replaceAll("\u00A0","-")
}
